import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    MenuItem,
    Paper,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React from "react";
import { TrackGoogleAnalyticsEvent } from "./GoogleAnalytics";

/*
chipAcquired
chipActive
chipAll
chipOpenSource
chipStalled


Blocked
New

*/

export default function ProjectsStatusFilter({
    activeProjectType,
    setActiveProjectType,
    wrangledProjects,
    appWrappedTranslator,
}) {
    const [
        projectsByStatusMoreInfoDialogIsOpen,
        setProjectsByStatusMoreInfoDialogIsOpen,
    ] = React.useState(false);

    const handleUniqueChipOnClick = (chipLabel) => {
        setActiveProjectType(chipLabel);
    };

    const chipTypes = wrangledProjects
        .filter(
            (projectChip) =>
                projectChip.chipLabel !== null &&
                projectChip.chipLabel !== "chipNew"
        )
        .map((project) => {
            return {
                chipLabel: project.chipLabel,
                chipColor: project.chipColor,
            };
        });

    chipTypes.push({
        chipLabel: "chipAll",
        chipColor: "default",
    });

    function sortChipsByName(a, b) {
        if (a.chipLabel > b.chipLabel) {
            return 1;
        } else if (a.chipLabel < b.chipLabel) {
            return -1;
        } else {
            return 0;
        }
    }

    // @todo clean up naming convention here
    const uniqueChips = chipTypes.filter(
        (tag, index, array) =>
            array.findIndex(
                (t) =>
                    t.chipLabel === tag.chipLabel &&
                    t.chipColor === tag.chipColor
            ) === index
    );

    const projectCount = (chipLabel) => {
        const chips = wrangledProjects.filter(
            (chip) => chip.chipLabel === chipLabel
        );

        if (chips.length === 0) return wrangledProjects.length;

        return chips.length;
    };

    const activeProjectTypeChipColor = (chipLabel) =>
        uniqueChips.filter((chip) => chip.chipLabel === chipLabel)[0].chipColor;

    return (
        <>
            <Dialog
                open={projectsByStatusMoreInfoDialogIsOpen}
                onClose={() =>
                    setProjectsByStatusMoreInfoDialogIsOpen(
                        !projectsByStatusMoreInfoDialogIsOpen
                    )
                }
            >
                {/* @todo @idea pull page dialoge? */}
                <DialogTitle>
                    {appWrappedTranslator(
                        "projectStatusFilterMoreInformationTitle"
                    )}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {appWrappedTranslator(
                            "projectStatusFilterMoreInformationContent"
                        )}
                    </DialogContentText>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        {appWrappedTranslator(
                                            "projectStatusFilterMoreInformationTableStatusCol"
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {appWrappedTranslator(
                                            "projectStatusFilterMoreInformationTableDefinitionCol"
                                        )}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        {appWrappedTranslator("chipAcquired")}
                                    </TableCell>
                                    <TableCell>
                                        {appWrappedTranslator(
                                            "chipAcquiredDefinition"
                                        )}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        {appWrappedTranslator("chipActive")}
                                    </TableCell>
                                    <TableCell>
                                        {appWrappedTranslator(
                                            "chipActiveDefinition"
                                        )}
                                        {/* {appWrappedTranslator("activeProjectSubheader")} */}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        {appWrappedTranslator("chipAll")}
                                    </TableCell>
                                    <TableCell>
                                        {appWrappedTranslator(
                                            "chipAllDefinition"
                                        )}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        {appWrappedTranslator("chipOpenSource")}
                                    </TableCell>
                                    <TableCell>
                                        {appWrappedTranslator(
                                            "chipOpenSourceDefinition"
                                        )}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setProjectsByStatusMoreInfoDialogIsOpen(
                                !projectsByStatusMoreInfoDialogIsOpen
                            );
                        }}
                    >
                        {appWrappedTranslator("buttonExit")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Stack
                id="projectChips"
                direction="row"
                spacing={1}
                // style={{
                //     background: "#00ff00",
                //     boxShadow: "inset 1px 1px 3px #471447",
                // }}
            >
                <FormControl
                    fullWidth
                    variant="standard"
                    style={{ padding: "0 15px" }}
                >
                    <Select
                        value={activeProjectType}
                        IconComponent={KeyboardArrowDownIcon}
                    >
                        {/* @todo @idea filter out select status from options */}
                        {/* .filter(chip => chip.chipLabel != activeProjectType) */}
                        {/* @note did not work first go, removed selected option from select */}
                        {uniqueChips
                            .sort(sortChipsByName)
                            .map(({ chipLabel }) => (
                                <MenuItem
                                    onClick={(event) => {
                                        event.preventDefault();
                                        handleUniqueChipOnClick(chipLabel);
                                        TrackGoogleAnalyticsEvent(
                                            `projectFilterItem${chipLabel}`,
                                            `projectFilterItem${chipLabel}Click`,
                                            `projectFilterItem${chipLabel}ClickLabel`
                                        );
                                    }}
                                    key={chipLabel}
                                    value={chipLabel}
                                >
                                    <Typography
                                        variant="body1"
                                        style={{ color: "#471447" }}
                                    >
                                        {appWrappedTranslator(chipLabel)}{" "}
                                        {appWrappedTranslator("projectLiteral")}
                                        <Chip
                                            size="small"
                                            label={`${projectCount(chipLabel)}`}
                                            color={activeProjectTypeChipColor(
                                                chipLabel
                                            )}
                                            style={{
                                                marginLeft: "5px",
                                                // color: "#00ff00",
                                            }}
                                            component="span"
                                        />
                                    </Typography>
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Stack>
        </>
    );
}
