/**

projects to maybe add

  some NIST foobar for open source jawns
  and some challenge thing(s)
  wod hopper?
  flagger POC
  what about the ABC jawn?
  mock code for philly?

*/

/**
  
  Fiscal Quarters

    January, February, and March (Q1)
    April, May, and June (Q2)
    July, August, and September (Q3)
    October, November, and December (Q4)


  The United States government Fiscal Year 2023 is:

    1st quarter: 1 October 2022 – 31 December 2022.
    2nd quarter: 1 January 2023 – 31 March 2023.
    3rd quarter: 1 April 2023 – 30 June 2023.
    4th quarter: 1 July 2023 – 30 September 2023

*/

/**

  template

  {
    companyName: "",
    link: "",
    acqQuarterYear: "",
    acqBy: "",
    imageSrc: "",
    description: "",
    isVisableOnSite: true,
    weight: 0,
    chipLabel: "",
    // chipColor: "",
    role: "",
    startDate: "",
    endDate: "",
    additonalArchiveChip: "",
    imagePadded: ""

  }

 */

/**

  img alt (SEO)

 */

// const this_month_and_year = `${new Date().getMonth()}/${new Date().getFullYear()}`;

// @idea infer color from `chipLabel`

// const chip_colors = {
//   default: "default",
//   primary: "primary",
//   secondary: "secondary",
//   error: "error",
//   info: "info",
//   success: "success",
//   warning: "warning",
// };

const chipLabelToColorMap = {
    chipAll: "default",
    chipActive: "primary",
    chipOpenSource: "secondary",
    // error: "error",
    // info: "info",
    chipAcquired: "success",
    chipStalled: "warning",
};

// {/* @todo @idea turn "<>.link" into array */}
// @todo website property

const projectsDataObject = [
    // ***************** TEMPLATE *****************
    // {
    //   companyName: "asdsda",
    //   link: "",
    //   acqQuarterYear: "asd",
    //   acqBy: "asads",
    //   imageSrc: "",
    //   description: "localeKeyTestLong",
    //   isVisableOnSite: false,
    //   weight: 10,
    //   chipLabel: "chipAcquired",
    //   // chipColor: chip_colors.success,
    //   role: "asdd",
    //   startDate: "asddsa",
    //   endDate: "adasd",
    //   additonalArchiveChip: "",
    //   imagePadded: "",
    // },
    // ***************************************************
    {
        companyName: "Look-up EIN",
        companyTagLine: "Augmented Government Business Data",
        link: "https://lookupein.com/",
        // link: "https://www.vyum.ai",
        acqQuarterYear: "",
        acqBy: "",
        imageSrc: "lookupein_logo.svg",
        imageAltText:
            "Union League Fitler Club PAFA Enterprise Joshua Mummert Stern Look up employee identification number ein free business",
        description:
            "This project is just for fun. A few buddies and I run into the whole " +
            "Employer Identification Number (EIN) blocker during general research and discovery. " +
            "This is our first iteration. We'll see what comes of it. Cool ideas being thrown around which is exciting.",
        isVisableOnSite: true,
        weight: 0,
        chipLabel: "chipActive",
        role: "Principal Contributor",
        startDate: "Q2'24",
        endDate: "",
    },
    {
        companyName: "ABCardiogram",
        website: "http://www.ABCardiogram.com",
        link: "http://www.ABCardiogram.com",
        links: ["http://www.ABCardiogram.com"],
        imageSrc: "abc.png",
        description:
            "Group fell apart during summer. They were wanting to build a better cardiogram.",
        isVisableOnSite: true,
        weight: -33,
        chipLabel: "chipStalled",
        role: "Co-Founder",
        startDate: "Q2'23",
        imagePadded: true,
    },
    {
        companyName: "BankMobile",
        companyTagLine: "banking-as-a-service platform",
        link: "https://www.americanbanker.com/news/whats-next-for-newly-public-bankmobile",
        acqQuarterYear: "Q1 ‘21",
        acqBy: "Customers Bancorp & Megalith Financial to form BM Technologies",
        imageSrc: "bankmobile_logo.jpg",
        description:
            "This one was billed as a banking-as-a-service platform disbursing financial aid refunds to college students while providing a full range of deposit products to retail customers. " +
            "Revenues were largely derived from interchange fees from customer debit card usage where were paid for by the merchant. " +
            "Also, BankMobile provided a white-label mobile banking solution.",
        isVisableOnSite: true,
        weight: 0,
        chipLabel: "chipAcquired",
        // chipColor: chip_colors.success,
        role:
            "Backend engineer, post-seed employee #19. Built out the API layer. " +
            "I worked on the white-label mobile banking team. " +
            "Great experience. Joined during the early days which enabled/exposed me...",
        startDate: "Q3'18",
        endDate: "Q1'21",
        imagePadded: true,
    },
    {
        companyName: "Haystack Informatics",
        companyTagLine: "HIPAA Compliance Analytics",
        link: "https://www.healthcareitnews.com/news/iatric-systems-buys-childrens-hospital-philadelphia-spinoff-haystack-informatics",
        acqQuarterYear: "Q1 ‘19",
        acqBy: "Iatric Systems, a subsidiary of Harris Healthcare",
        imageSrc: "haystack_logo.jpg",
        description:
            "SAAS enterprise analytics platform which helped healthcare organizations safeguard and optimize their operations. " +
            "This was a very small shop! " +
            "I learned the impact a single team member can yield. " +
            "There were three of us before due diligence kicked off. " +
            "One mope who well, was left wanting, was cut out. " +
            "Just a shoddy dev. " +
            "However the third team member, the CEO <a href='https://www.linkedin.com/in/adriantalapan' target='_blank'>Adrian Talapan</a>, was a stand-up leader and engineer. " +
            "As well as a serial entrepreneur. " +
            "I learned a lot from him. " +
            "Very thankful for the experience and exposure.",
        isVisableOnSite: true,
        weight: 0,
        chipLabel: "chipAcquired",
        role: "Full-stack Engineer, employee #3",
        startDate: "Q3'17",
        endDate:
            "Q2‘20. After the acquisition, I stayed on with Iatric Systems to port over our tech before leaving. Overall, very insightful experience.",
        imagePadded: true,
    },
    {
        companyName: "FlockU",
        companyTagLine: "Digital Media",
        link: "https://technical.ly/professional-development/flocku-acquisition/",
        acqQuarterYear: "Q1 ‘18",
        acqBy: "Becker Associates",
        imageSrc: "flocku_logo.png",
        description:
            "New type of digital hub created for students and written by students, with some savvy adults chiming in along the way. " +
            "We were basically a blog designed to be acquired. Cool experience.",
        isVisableOnSite: true,
        weight: 0,
        chipLabel: "chipAcquired",
        // chipColor: chip_colors.success,
        role: "Full-stack Engineer, pre-seed hire #7",
        startDate: "Q2'16",
        endDate: "Q1'18",
        imagePadded: true,
    },
    {
        companyName: "Second Front Systems",
        companyTagLine: "Bring emerging technologies to the DoD",
        // link:
        //   "https://www.crn.com/news/security/300104576/qualys-to-buy-federal-solution-provider-second-front-systems-to-boost-government-presence.htm",
        acqQuarterYear: "Q2 ‘18",
        acqBy: "Qualys",
        imageSrc: "second_front_systems_logo.png",
        description:
            "Billed as a public benefit software company that equips defense and national security professionals with access to emerging technologies. " +
            "Aside, the research team there is the real keystone. " +
            "I was employee #5. " +
            "I bought into the program because of a great man by the name of Mark Andrew Butler, Col. USMC (Ret.). " +
            "Stand up fella. Sharp. Measured. One-of-a-kind. " +
            "Unfortunately, <a href='https://www.pumphreyfuneralhome.com/obituaries/Mark-Andrew-Butler-Col-USMC-Ret?obId=25006975' target='_blank'>he passed away</a> a few years into the project which shook up things. " +
            "Afterwards, I cashed out at the first chance. I will never forget Mark.",
        isVisableOnSite: true,
        weight: -1,
        chipLabel: "chipAcquired",
        // chipColor: chip_colors.success,
        role: "Principal, #1 engineering hire (#6 overall)",
        startDate: "Q1'16",
        endDate: "Q3'22",
        imagePadded: true,
    },
    {
        companyName: "Star Group",
        companyTagLine: "Digital Media",
        link: "https://www.mmm-online.com/home/channel/agencies/star-group-merges-with-vox-medica-calcium-nyc/",
        acqQuarterYear: "Q2 ‘14",
        acqBy: "Vox Medical/Calcium",
        imageSrc: "star_group_logo.jpg",
        description:
            "Philly based, women-owned, marketing and communications firm. " +
            "Short lived but a fun run. " +
            "Learned enough about the agency model to steer away from it.",
        isVisableOnSite: true,
        weight: 0,
        chipLabel: "chipAcquired",
        // chipColor: chip_colors.success,
        role: "Web Developer",
        startDate: "Q3'12",
        endDate: "Q2'14",
    },
    {
        companyName: "MTConnect DOD",
        companyTagLine: "production optimization solution",
        link: "https://www.youtube.com/watch?v=dC4uj3cyQWA",
        acqQuarterYear: "Q3 '14",
        acqBy: "DOD",
        imageSrc: "mt_connect_dod_logo.png",
        description:
            "Lean Scheduler was a production scheduling application for discrete manufacturing based on Advanced Planning and Scheduling (APS) trend of finite capacity scheduling.",
        isVisableOnSite: true,
        weight: 2,
        chipLabel: "chipOpenSource",
        // chipColor: chip_colors.secondary,
        role: "Team member, team of two",
        startDate: "Q2'12",
        endDate: "Q1'13",
    },
    {
        // companyName: "Code for Philly: PLSE",
        companyName: "PLSE",
        companyTagLine: "Code for Philly",
        link: "https://codeforphilly.org/projects/philadelphia_lawyers_for_social_equity_-_record_expungement",
        acqQuarterYear: "",
        acqBy: "",
        imageSrc: "plse_logo.jpg",
        description:
            "This one is a ongoing Code for America project made up of people-centered problem solvers. " +
            "We built out an expungement petition generator to help the Philadelphia Lawyers for Social Equity (PLSE).",
        isVisableOnSite: true,
        weight: 0,
        chipLabel: "chipActive",
        // chipLabel: "chipOpenSource",
        // chipColor: chip_colors.secondary,
        role: "Database/ETL Engineer, fluctuating team size (six +/- three)",
        startDate: "Q2'19",
        endDate:
            "Q1 '21. I still pop in now-and-then to help out where I can. " +
            "However, the bulk of work is complete and supported by new Code for Philly members.",
    },
    {
        companyName: "NomPeer",
        companyTagLine: "Alternative Dispute Resolution Platform",
        link: "http://nompeer.com/",
        acqQuarterYear: "",
        acqBy: "",
        imageSrc: "nompeer_logo.jpg",
        description:
            "This one has been revived a few times. " +
            "My buddy cooked this one up a few years ago. " +
            "We're currently building out the POC v2. " +
            "Gearing up for the funding shuffle in Q2 '24.",
        // isVisableOnSite: true,
        // isVisableOnSite: false, // @note does not deactivate/hide project card
        weight: 1,
        chipLabel: "chipActive",
        // chipLabel: "chipInactive", // @note does deactivate/hide project card
        // chipColor: chip_colors.primary,
        role: "Founder, team of two",
        startDate: "Q2'21",
        endDate: "",
        imagePadded: true,
    },
    {
        companyName: "Vyum.Ai",
        companyTagLine: "AI-powered Trading",
        website: "https://www.vyum.ai",
        // link: "https://www.vyum.ai",
        acqQuarterYear: "",
        acqBy: "",
        imageSrc: "vyum_autogen_logo.png",
        // imageSrc: "vyum_logo_resized.png",
        description:
            'This project is legally in "stealth mode" as of Q2 \'22. ' +
            "You can visit the landing page to sign up for the newsletter. " +
            "That'll keep you in the loop. " +
            "We're looking for a new broker since Ally dropped their API services so please pass along any and all suggestions.",
        isVisableOnSite: true,
        weight: -1,
        chipLabel: "chipActive",
        role: "Founder",
        startDate: "Q1'20",
        endDate: "",
    },
    {
        companyName: "Center City Gazette",
        companyTagLine: "Hyper-local news aggregator",
        link: "https://www.centercitygazette.com",
        acqQuarterYear: "",
        acqBy: "",
        imageSrc: "ccg_pseudo_snippet.png",
        description:
            "This is a passion project type-thing. It started off as a script I ran to collect local news bytes and bobs. Basically a time saving initiative. It then morphed into well, what it is now. Nothing to call home about but hey, check it out. The site is a collective of hyperlocal news publications from within and around Center City Philadelphia. I'm currently working through some ETL problems when the mood strikes. In the meantime, I'm soliciting original content creators who live in the mix to contribute type-thing. Please reach out if you're interested.",
        isVisableOnSite: true,
        weight: -13,
        chipLabel: "chipStalled",
        role: "Founder, team of one at this time",
        startDate: "Q4'22",
        endDate: "",
    },
    {
        companyName: "I Heart WC",
        link: "http://www.iheartwc.com",
        acqQuarterYear: "",
        acqBy: "",
        imageSrc: "wc_flag.png",
        description:
            "This was a index of all the shops, restraunts, and bars in my hometown of West Chester, Pennsylvania. We keep it running for a year or so then it kind of withered after I moved back to city. Fun project. Got a few free beers out of it so nothing was lost! The domain is now a placeholder. We'll see what comes!",
        isVisableOnSite: false,
        weight: -13,
        chipLabel: "chipStalled",
        role: "Founder, team of two",
        startDate: "Q1'14",
        endDate: "Q3'16",
    },
    {
        companyName: "TenTen Race Street Trust",
        companyTagLine: "REIT & Direct-to-collector Art Reseller",
        // Alternative Investing
        link: "http://tentenracestreettrust.com",
        acqQuarterYear: "",
        acqBy: "",
        imageSrc: "1010-Race-29.jpg",
        description:
            "Early stage REIT we spun up during Q1 '21. " +
            "In short, we purchased a portfolio in this historical building in Chinatown then figured we'd make a whole thing out of it. " +
            "The long-term gist is to own and finance income-producing properties in Center City Philly. " +
            'This initiative kicked off in the heart of Philly\'s Chinatown neighborhood at 1010 Race Street which is the "genesis property," hence the name. ' +
            "Please feel free to reach out if you'd like to learn more.",
        isVisableOnSite: true,
        weight: 0,
        chipLabel: "chipActive",
        // chipColor: chip_colors.primary,
        role: "Partner & Chief Responsibility Officer, team of three and looking to grow",
        startDate: "Q4'21",
        endDate: "",
    },
    {
        companyName: "Kut Solution",
        link: "",
        acqQuarterYear: "",
        acqBy: "",
        imageSrc: "ks_logo.png",
        description:
            "This one started off as a system to faciliate the process of cutting weight for wrestlers. I grew up wrestling and did so on a full-ride through college and the international circuit for a bit. I stood this up with a college practice partner of mine who ended up coaching at a few univerisities. After he stepped away from the sport, this project fizzeled out. Fun experience and learned a lot. We'll see what happens in the future!",
        isVisableOnSite: false,
        weight: -13,
        chipLabel: "chipStalled",
        role: "Founder, team of two",
        startDate: "Q1'12",
        endDate: "Q2'13",
    },
];

export const wrangledProjects_old = projectsDataObject;

export const wrangledProjects = projectsDataObject.map((project) => {
    project.chipColor = chipLabelToColorMap[project.chipLabel];

    // if (project.isVisableOnSite) {
    return project;
    // }
});
