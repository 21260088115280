import * as React from "react";
import { Alert, AlertTitle, Snackbar } from "@mui/material";

export default function Alerts({
  alertsIsOpen,
  setAlertsIsOpen,
  appWrappedTranslator,
}) {
  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertsIsOpen(false);
  };

  // @idea full page alert in dialouge
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={alertsIsOpen}
      onClose={handleClose}
      autoHideDuration={9000}
    >
      <Alert
        onClose={handleClose}
        severity="success"
        sx={{ width: "100%" }}
        style={{ padding: "30px" }}
      >
        {/* @todo typography */}
        <AlertTitle>{appWrappedTranslator("alertTextSuccess")}</AlertTitle>
        {/* @todo typography */}
        {appWrappedTranslator("alertsSuccessFormSubmit")}
      </Alert>
      {/* @todo failure */}
      {/* @todo i18n */}
      {/* <Alert variant="outlined" severity="error">
        This is an error alert — check it out!
      </Alert> */}
    </Snackbar>
  );
}
