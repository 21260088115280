import LoadingButton from "@mui/lab/LoadingButton";
import {
    Dialog,
    DialogTitle,
    FormGroup,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TrackGoogleAnalyticsEvent } from "./GoogleAnalytics";

// @idea make full page, and mirror alert to not confuse user
export default function ContactForm({
    contactFormIsOpen,
    setContactFormIsOpen,
    setAlertsIsOpen,
    contactFormMessage,
    setContactFormMessage,
    appWrappedTranslator,
}) {
    const [fullName, setFullName] = React.useState("");
    const [fullNameIsValid, setFullNameIsValid] = useState(null);
    const [email, setEmail] = useState("");
    const [emailIsValid, setEmailIsValid] = useState(null);
    const [messageIsValid, setMessageIsValid] = useState(null);
    const [formIsValid, setFormIsValid] = useState(false);
    const [formInLoadingState, setFormInLoadingState] = useState(false);

    const requestHeaders = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key":
            "008188175e660dbeb07737cf8c3dc576:1364cb55eca25adb988944fe786845fe2341a80b9676d06dc1e5dda0b1bffba2d7691342a55c390dfc39d6a025e14968",
    };

    if (process.env.NODE_ENV === "development") {
        requestHeaders["x-mock-only"] = "true";
    }

    const validateFullName = (event) => {
        const regex = new RegExp("\\w{2,}\\s\\w*");

        if (fullName.length) {
            setFullNameIsValid(regex.test(event.target.value));
        } else {
            setFullNameIsValid(null);
        }
    };

    const validateEmail = (event) => {
        const regex = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);

        if (email.length) {
            setEmailIsValid(regex.test(event.target.value));
        } else {
            setEmailIsValid(null);
        }
    };

    const validateMessage = (event) => {
        const regex = new RegExp(/^\w+|\W+/);

        if (contactFormMessage.length) {
            setMessageIsValid(regex.test(event.target.value));
        } else {
            setMessageIsValid(null);
        }
    };

    // if `message` value populated from project, mark field as valid
    useEffect(() => {
        // line below caused error on odd char count
        // if (contactFormMessage.length && !messageIsValid) {
        if (contactFormMessage.length) {
            setMessageIsValid(true);
        } else {
            setMessageIsValid(false);
        }
    }, [contactFormMessage]);

    // confirm all fields are valid to enable submit
    useEffect(() => {
        if (fullNameIsValid && emailIsValid && messageIsValid) {
            setFormIsValid(!formIsValid);
        } else {
            setFormIsValid(false);
        }
    }, [fullNameIsValid, emailIsValid, messageIsValid]);

    // clean up on pseudo "unmount"
    useEffect(() => {
        if (!contactFormIsOpen) {
            setFormInLoadingState(false);
            setFullName("");
            setEmail("");
            setContactFormMessage("");
            setFormIsValid(null);
        }
    }, [contactFormIsOpen]);

    const handleOnSubmit = async (event) => {
        event.preventDefault();

        TrackGoogleAnalyticsEvent(
            "contactFormSubmitButton",
            "contactFormSubmitButtonClick",
            "contactFormSubmitButtonClickLabel"
        );

        setFormInLoadingState(true);

        try {
            let res = await fetch(`https://api.vyum.ai/api/send-email`, {
                headers: {
                    ...requestHeaders,
                },
                method: "POST",
                body: JSON.stringify({
                    fullName: event.target.fullName.value,
                    email: event.target.email.value,
                    message: event.target.message.value,
                    sendtoEmail: "jm@joshuamummert.com",
                    emailSubject: "jm.com contact form submission",
                }),
            });

            await res.json();

            if (res.status === 200) {
                setContactFormIsOpen(!contactFormIsOpen);
                setAlertsIsOpen(true);
            } else {
                // @todo
                console.log("not ok");
                // this.setState(() => ({
                //   formSubmittedFailed: true,
                //   formSubmitted: false,
                // }));
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Dialog
            open={contactFormIsOpen}
            fullWidth={true}
            onClose={() => setContactFormIsOpen(!contactFormIsOpen)}
        >
            <DialogTitle style={{ textAlign: "center" }}>
                {appWrappedTranslator("contactFormTitle")}
            </DialogTitle>
            <Stack
                id="contactFormWrapper"
                direction="column"
                spacing={2}
                alignItems="center"
                justifyContent="center"
            >
                <form id="contactForm" onSubmit={handleOnSubmit}>
                    <FormGroup>
                        <TextField
                            className="contact-form-field"
                            required
                            label={appWrappedTranslator("contactFormFullName")}
                            variant="outlined"
                            name="fullName"
                            value={fullName}
                            onChange={(event) => {
                                setFullName(event.target.value);
                            }}
                            onBlur={(event) => {
                                validateFullName(event);
                            }}
                            error={
                                (fullName.length &&
                                    fullNameIsValid === false) ||
                                false
                            }
                            color={
                                fullName.length && fullNameIsValid
                                    ? "success"
                                    : "primary"
                            }
                            focused={
                                fullName.length && fullNameIsValid
                                    ? true
                                    : false
                            }
                        />
                        <TextField
                            className="contact-form-field"
                            required
                            label={appWrappedTranslator("contactFormEmail")}
                            variant="outlined"
                            name="email"
                            value={email}
                            onChange={(event) => {
                                setEmail(event.target.value);
                            }}
                            onBlur={(event) => {
                                validateEmail(event);
                            }}
                            error={
                                (email.length && emailIsValid === false) ||
                                false
                            }
                            color={
                                email.length && emailIsValid
                                    ? "success"
                                    : "primary"
                            }
                            focused={
                                email.length && emailIsValid ? true : false
                            }
                        />
                        <TextField
                            className="contact-form-field"
                            required
                            label={appWrappedTranslator("contactFormMessage")}
                            variant="outlined"
                            multiline
                            rows={4}
                            name="message"
                            value={contactFormMessage}
                            onChange={(event) => {
                                setContactFormMessage(event.target.value);
                            }}
                            onBlur={(event) => {
                                validateMessage(event);
                            }}
                            error={
                                (contactFormMessage.length &&
                                    messageIsValid === false) ||
                                false
                            }
                            color={
                                contactFormMessage.length && messageIsValid
                                    ? "success"
                                    : "primary"
                            }
                            focused={
                                contactFormMessage.length && messageIsValid
                                    ? true
                                    : false
                            }
                        />
                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                            justifyContent={"center"}
                            id="contactFormButtons"
                        >
                            <LoadingButton
                                id="contactFormSubmitButton"
                                type="submit"
                                variant="contained"
                                disabled={!formIsValid}
                                loading={formInLoadingState}
                                // style={{
                                //     background: "#471447",
                                //     color: "#00ff00",
                                // }}
                            >
                                <Typography variant="button">
                                    {appWrappedTranslator("contactFormSubmit")}
                                </Typography>
                            </LoadingButton>
                        </Stack>
                    </FormGroup>
                </form>
            </Stack>
        </Dialog>
    );
}
