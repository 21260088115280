import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./App.css";
import reportWebVitals from "./reportWebVitals";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
} from "@mui/material/styles";
// import { pink } from "@mui/material/colors";

// https://nodejs.dev/en/learn/nodejs-the-difference-between-development-and-production/
if (process.env.NODE_ENV !== "production") {
  require("dotenv").config();
}

// @todo late stage, theme stuff
// https://mui.com/material-ui/experimental-api/css-theme-variables/customization/
const theme = extendTheme({
  // colorSchemes: {
  //   light: {
  //     palette: {
  //       primary: {
  //         main: "#00ff00",
  //       },
  //     },
  //   },
  //   dark: {
  //     palette: {
  //       primary: {
  //         main: pink[400],
  //       },
  //     },
  //   },
  // },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CssVarsProvider theme={theme}>
      <App />
    </CssVarsProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
