import Masonry from "@mui/lab/Masonry";
import Box from "@mui/material/Box";
import React, { useEffect, useRef, useState } from "react";
import Project from "./Project";
import ProjectsStatusFilter from "./ProjectsStatusFilter";

export default function Projects({
    wrangledProjects,
    setContactFormIsOpen,
    setContactFormMessage,
    appWrappedTranslator,
}) {
    const [activeProjectType, setActiveProjectType] = useState("chipActive");
    const [height, setHeight] = useState(0);
    const [expandedCompanyName, setExpandedCompanyName] = useState(null);
    const ref = useRef(null);

    useEffect(() => {
        setHeight(ref.current.clientHeight);
    });

    React.useEffect(() => {
        setExpandedCompanyName(null);
    }, [activeProjectType]);

    // @todo clean up (else if ...)
    function sortProjectsByWeightProperty(a, b) {
        if (a.weight < b.weight) {
            return 1;
        }
        if (a.weight > b.weight) {
            return -1;
        }
        return 0;
    }

    return (
        <>
            {/* @idea make #projectNavChipsContainer background #471447 and text color #0f0 */}
            <div id="projectNavChipsContainer" ref={ref}>
                <div id="projectNavChipsWrapper">
                    <ProjectsStatusFilter
                        activeProjectType={activeProjectType}
                        setActiveProjectType={setActiveProjectType}
                        wrangledProjects={wrangledProjects}
                        appWrappedTranslator={appWrappedTranslator}
                    />
                </div>
            </div>
            <Box>
                <div
                    id="projectMasonryWrapper"
                    style={{
                        paddingTop: `${height + 19}px`,
                    }}
                >
                    <Masonry
                        columns={{ xs: 1, sm: 3, md: 3 }}
                        spacing={2}
                        style={{
                            margin: 0,
                            alignContent: "center",
                            animation: "fadeIn 1.25s ease",
                        }}
                    >
                        {wrangledProjects
                            .sort(sortProjectsByWeightProperty)
                            .filter((project) => {
                                if (
                                    project.chipLabel === activeProjectType ||
                                    activeProjectType === "chipAll" ||
                                    project.chipLabel === "chipNew"
                                ) {
                                    return project;
                                } else {
                                    return false;
                                }
                            })
                            .map((project) => {
                                return (
                                    <Project
                                        key={project.companyName
                                            .toLowerCase()
                                            .replaceAll(" ", "_")}
                                        project={project}
                                        setContactFormIsOpen={
                                            setContactFormIsOpen
                                        }
                                        setContactFormMessage={
                                            setContactFormMessage
                                        }
                                        appWrappedTranslator={
                                            appWrappedTranslator
                                        }
                                        expandedCompanyName={
                                            expandedCompanyName
                                        }
                                        setExpandedCompanyName={
                                            setExpandedCompanyName
                                        }
                                    />
                                );
                            })}
                    </Masonry>
                </div>
            </Box>
        </>
    );
}
