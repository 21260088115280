import EmailIcon from "@mui/icons-material/Email";
import GitHubIcon from "@mui/icons-material/GitHub";
import LanguageIcon from "@mui/icons-material/Language";
import {
    AppBar,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import * as React from "react";
import { TrackGoogleAnalyticsEvent } from "./GoogleAnalytics";
import { languages, languages_nativeSpelling_map } from "./translator";

export default function Header({
    activeLanguage,
    setActiveLanguage,
    setActiveLanguageKey,
    contactFormIsOpen,
    setContactFormIsOpen,
    projectsVisable,
    setProjectsVisable,
    appWrappedTranslator,
}) {
    const [menuIsOpen, setMenuIsOpen] = React.useState(false);

    const ref = React.useRef(null);

    const handleLanguageMenuOnClick = (event) => {
        event.preventDefault();

        setMenuIsOpen(!menuIsOpen);

        // TrackGoogleAnalyticsEvent(
        //     "i18nMenu",
        //     "i18nMenuClick",
        //     "i18nMenuClickLabel"
        // );

        if (event.target.getAttribute("data-language")) {
            const lang = event.target.getAttribute("data-language");
            const langKey = lang.slice(0, 2).toLowerCase();

            setActiveLanguage(lang);
            setActiveLanguageKey(langKey);
        }
    };
    return (
        <div id="header">
            <Box sx={{ pb: 7 }} ref={ref}>
                <Paper
                    sx={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                    }}
                    elevation={3}
                >
                    <AppBar id="headerAppBar" position="static">
                        <Toolbar
                            sx={{
                                paddingLeft: "10px !important",
                                paddingRight: "10px !important",
                            }}
                        >
                            {/* @idea find diff component to hold 3 options/icons? */}
                            <Box sx={{ flexGrow: 1 }} id="appBarMenuIcon">
                                <Tooltip
                                    title={appWrappedTranslator(
                                        "tooltipContact"
                                    )}
                                >
                                    <EmailIcon
                                        className={"nav-icon"}
                                        id="emailIcon"
                                        fontSize="large"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            TrackGoogleAnalyticsEvent(
                                                "headerContactIcon",
                                                "headerContactIconClick",
                                                "headerContactIconClickLabel"
                                            );
                                            setContactFormIsOpen(
                                                !contactFormIsOpen
                                            );
                                        }}
                                    />
                                </Tooltip>
                                {/* <a
                                    id="calendlyIconLink"
                                    href="https://calendly.com/joshuamummert"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Tooltip
                                        title={appWrappedTranslator(
                                            "tooltipCalendarly"
                                        )}
                                    >
                                        <CalendarMonthIcon
                                            className={"nav-icon"}
                                            fontSize="large"
                                        />
                                    </Tooltip>
                                </a> */}
                                <a
                                    id="githubIconLink"
                                    href="https://github.com/speaud/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Tooltip title="GitHub">
                                        <GitHubIcon
                                            className={"nav-icon"}
                                            fontSize="large"
                                        />
                                    </Tooltip>
                                </a>
                                {/* <a
                                    id="githubIconLink"
                                    href="https://www.crunchbase.com/person/joshua-mummert-stern"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Tooltip title="Crunchbase">
                                        <BusinessIcon
                                            className={"nav-icon"}
                                            fontSize="large"
                                        />
                                    </Tooltip>
                                </a> */}
                            </Box>
                            {/* @todo @idea remove/replace with something to nav home?... */}
                            {/* <Box sx={{ flexGrow: 1 }}>
                                {projectsVisable && (
                                    <Tooltip
                                        title={appWrappedTranslator(
                                            "tooltipHome"
                                        )}
                                    >
                                        <KeyboardDoubleArrowUpIcon
                                            fontSize="large"
                                            style={{
                                                marginLeft: "10px",
                                                cursor: "pointer",
                                            }}
                                            className={"nav-icon"}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                setProjectsVisable(
                                                    !projectsVisable
                                                );
                                            }}
                                        />
                                    </Tooltip>
                                )}
                            </Box> */}
                            <Box sx={{ display: { md: "flex" } }}>
                                <IconButton
                                    id="appBarMenuLanguagesButton"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        TrackGoogleAnalyticsEvent(
                                            "i18nMenu",
                                            "i18nMenuClick",
                                            "i18nMenuClickLabel"
                                        );
                                        handleLanguageMenuOnClick(event);
                                    }}
                                    sx={{ paddingRight: "0" }}
                                >
                                    <Typography>
                                        <span
                                            className="nav-label"
                                            style={{ paddingRight: "5px" }}
                                        >
                                            {
                                                languages_nativeSpelling_map[
                                                    activeLanguage
                                                ].nativeSpelling
                                            }
                                        </span>
                                    </Typography>
                                    <Tooltip
                                        title={appWrappedTranslator(
                                            "tooltipChangeLanguage"
                                        )}
                                    >
                                        {/* @todo fix size/style compared to left side icons */}
                                        <LanguageIcon
                                            className="nav-icon"
                                            fontSize="large"
                                        />
                                    </Tooltip>
                                </IconButton>
                                <Menu
                                    id="appBarMenuLanguages"
                                    anchorEl={() =>
                                        document.querySelector(
                                            "#appBarMenuLanguagesButton"
                                        )
                                    }
                                    open={menuIsOpen}
                                    onClick={(event) => {
                                        event.preventDefault();

                                        handleLanguageMenuOnClick(event);
                                    }}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                >
                                    {languages
                                        .filter(
                                            (language) =>
                                                language !== activeLanguage
                                        )
                                        .sort()
                                        .map((x, i) => (
                                            <MenuItem
                                                onClick={(event) => {
                                                    event.preventDefault();

                                                    setMenuIsOpen(!menuIsOpen);

                                                    TrackGoogleAnalyticsEvent(
                                                        `i18nMenuItem${x}`,
                                                        `i18nMenuItem${x}Click`,
                                                        `i18nMenuItem${x}ClickLabel`
                                                    );
                                                }}
                                                key={i}
                                                data-language={x}
                                            >
                                                {
                                                    languages_nativeSpelling_map[
                                                        x
                                                    ].nativeSpelling
                                                }
                                            </MenuItem>
                                        ))}
                                </Menu>
                            </Box>
                        </Toolbar>
                    </AppBar>
                </Paper>
            </Box>
        </div>
    );
}
