import * as React from "react";
import { SEO_ALT_STRING } from "./constants";
// import React, { useState, useEffect, useRef } from "react";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import LinkIcon from "@mui/icons-material/Link";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import { Alert, AlertTitle, Button, Chip } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
// import { useEffect, useState } from "react";

/**
  i18n keys to make

    ??? contactFormMessageFromActiveProject

*/

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}));

// @idea use https://mui.com/material-ui/react-skeleton/ instead of loading jawn
function PreloadedImage({ imageSrc, imageAltText }) {
    const [activeImageSrc, setActiveImageSrc] = React.useState(
        "rotate-pulsating-loading-animation.gif"
    );
    // const [imageLoaded, setImageLoaded] = useState(false);

    const cssClasses = [
        "MuiCardMedia-root",
        "MuiCardMedia-media",
        "MuiCardMedia-img",
        "css-o69gx8-MuiCardMedia-root",
    ];

    return (
        <>
            <img
                style={{ width: "100%", minHeight: "10px" }}
                className={cssClasses.join(" ")}
                src={activeImageSrc || "cheshire_cat.png"}
                alt={imageAltText + " " + SEO_ALT_STRING}
                onLoad={() => {
                    // setImageLoaded(!imageLoaded);
                    setActiveImageSrc(imageSrc);
                }}
            />
        </>
    );
}

export default function Project({
    project: {
        companyName,
        companyTagLine,
        website,
        link,
        links,
        imageSrc,
        imageAltText,
        description,
        chipLabel,
        chipColor,
        role,
        startDate,
        endDate,
        imagePadded,
    },
    setContactFormIsOpen,
    setContactFormMessage,
    appWrappedTranslator,
    expandedCompanyName,
    setExpandedCompanyName,
}) {
    const [expanded, setExpanded] = React.useState(false);

    const snippetMaxLength = 73;

    // const myRef = useRef(null);

    const handleExpandClick = (currentExpandedCompanyName) => {
        setExpandedCompanyName(currentExpandedCompanyName);
        // myRef.current.scrollIntoView("alignToTop");
        // console.log("action here");
        setExpanded(!expanded);
    };

    const generateContentSnippet = () => {
        const translatedContent = appWrappedTranslator(description).toString();

        return `${translatedContent.substring(0, snippetMaxLength)}...`;
    };

    const generateContentContinued = () => {
        const translatedContent = appWrappedTranslator(description).toString();

        return `...${translatedContent.substring(
            snippetMaxLength,
            translatedContent.length
        )}`;
    };

    React.useEffect(() => {
        if (expandedCompanyName !== companyName) {
            setExpanded(false);
        }
    }, [expandedCompanyName]);

    return (
        // <Card className="proect" style={expanded ? { width: "100%" } : {}}>
        <Card
            className="project"
            // style={{
            //     border: "1px solid #00ff00",
            //     boxShadow: "inset 1px 1px 1px #471447",
            // }}
        >
            <CardMedia
                style={{ minHeight: "10px" }}
                className={imagePadded && "project-card-image-padded"}
                children={
                    <PreloadedImage
                        imageSrc={imageSrc}
                        imageAltText={imageAltText + " " + SEO_ALT_STRING}
                    />
                }
                onClick={(event) => {
                    if (companyName !== "Vyum.Ai") {
                        event.preventDefault();
                        handleExpandClick(companyName);
                    }
                }}
            />
            {/* @todo @note the flicker is related to the <TYp.. component */}
            <CardContent
                className="project-content-snippet"
                onClick={(event) => {
                    if (companyName !== "Vyum.Ai") {
                        event.preventDefault();
                        handleExpandClick(companyName);
                    }
                }}
            >
                {/* <Typography variant="body1" ref={myRef}> */}
                <Typography variant="body1">{companyName}</Typography>
                {companyTagLine && (
                    <>
                        <Typography
                            variant="caption"
                            color="text.secondary"
                            component="div"
                            style={{ textTransform: "capitalize" }}
                        >
                            {companyTagLine}
                        </Typography>
                        <hr className="hr-default" />
                    </>
                )}
                {companyName === "Vyum.Ai" && (
                    <Alert style={{ margin: "4% 0" }} severity="info">
                        <AlertTitle>Special Info</AlertTitle>
                        We're searching for a new brokeage firm due to a breech
                        of contract by our former vendor. This project is "on
                        hold," until we sort that and a few other things out.
                        Hang tight.{" "}
                        <a
                            href="http://www.vyum.ai"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Please sign up for the project-specific newsletter
                            for reqular status updates.
                        </a>
                    </Alert>
                )}
                {companyName !== "Vyum.Ai" && (
                    <Typography variant="body2" color="text.secondary">
                        {generateContentSnippet()}
                    </Typography>
                )}
            </CardContent>
            {companyName !== "Vyum.Ai" && (
                <CardActions className="project-content-actions" disableSpacing>
                    <div className="project-chips">
                        <Chip
                            className="project-chip"
                            size="small"
                            label={appWrappedTranslator(chipLabel)}
                            color={chipColor}
                        />
                    </div>
                    {/* @todo prevent overflow x on language change (ex rus) */}
                    <div className="project-expand">
                        <ExpandMore
                            expand={expanded}
                            onClick={(event) => {
                                event.preventDefault();
                                handleExpandClick(companyName);
                            }}
                            aria-expanded={expanded}
                            aria-label="show more"
                            className={"project-expand-icon"}
                        >
                            <KeyboardDoubleArrowDownIcon id={companyName} />
                        </ExpandMore>
                    </div>
                </CardActions>
            )}
            {/* @idea @todo website property, maybe as icon? */}
            {/* https://mui.com/material-ui/material-icons/?query=website */}
            {/* @idea accordian, or some other interactive dislay element */}
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent className="project-content-description">
                    {description.length > 0 && (
                        <>
                            <Typography variant="body2" color="text.secondary">
                                <b>
                                    {appWrappedTranslator(
                                        "projectDescriptionContinued"
                                    )}
                                    :{" "}
                                </b>
                                {generateContentContinued()}
                            </Typography>
                            <hr className="hr-default" />
                        </>
                    )}
                    {role && (
                        <>
                            <Typography variant="body2" color="text.secondary">
                                <b>{appWrappedTranslator("projectRole")}: </b>
                                {role}
                            </Typography>
                            <hr className="hr-default" />
                        </>
                    )}
                    {startDate && (
                        <>
                            <Typography variant="body2" color="text.secondary">
                                <b>
                                    {appWrappedTranslator("projectStarted")}:{" "}
                                </b>
                                {startDate}
                            </Typography>
                            <hr className="hr-default" />
                        </>
                    )}

                    {endDate && (
                        <>
                            <Typography variant="body2" color="text.secondary">
                                <b>{appWrappedTranslator("projectExited")}: </b>
                                {endDate}
                            </Typography>
                            <hr className="hr-default" />
                        </>
                    )}
                    {website && (
                        <>
                            <a
                                href={website}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <OpenInBrowserIcon />
                            </a>
                        </>
                    )}
                    {link && (
                        <>
                            <a
                                href={link}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button
                                    id="learnMoreButton"
                                    style={{
                                        width: "100%",
                                        marginBottom: "2%",
                                    }}
                                >
                                    <Typography variant="button">
                                        Source{" "}
                                        {appWrappedTranslator("projectLink")}
                                    </Typography>
                                </Button>
                            </a>
                            {/* <hr className="hr-default" /> */}
                        </>
                    )}
                    {links && links.length && (
                        <>
                            {links.map((i, linkValue) => {
                                return (
                                    <a
                                        key={i}
                                        href={linkValue}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <LinkIcon />
                                    </a>
                                );
                            })}
                        </>
                    )}
                    {/* @idea add icon action to make project full screen */}
                    {/* @idea move action to closer top to with goal to make it more visable */}
                    {chipLabel === "chipActive" && (
                        <>
                            {/* <hr /> */}
                            <Button
                                id="learnMoreButton"
                                color="primary"
                                variant="outlined"
                                style={{
                                    width: "100%",
                                    // color: "#00ff00",
                                    // background: "rgba(71, 20, 71, 1)",
                                }}
                                onClick={() => {
                                    setContactFormIsOpen(true);
                                    setContactFormMessage(
                                        `I would like to learn more about ${companyName}`
                                    );
                                }}
                            >
                                <Typography variant="button">
                                    {appWrappedTranslator(
                                        "projectActiveLearnMore"
                                    )}
                                </Typography>
                            </Button>
                        </>
                    )}
                </CardContent>
            </Collapse>
        </Card>
    );
}
