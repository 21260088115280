// import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";

// ReactGA.event(
//   category: 'Button',
//   action: 'Click',
//   label: 'Contact Us'
// });

export const InitializeGoogleAnalytics = () => {
    // Initialize UA
    // ReactGA.initialize("UA-XXXXXXXX-X");
    // Initialize GA4 - Add your measurement ID
    ReactGA4.initialize("G-M5S2L4ZMHM");

    console.log("ReactGA4 INITIALIZED");
};

export const TrackGoogleAnalyticsEvent = (category, action, label) => {
    console.log("GA4 event:", category, ":", action, ":", label);

    ReactGA4.event({
        category: category,
        action: action,
        label: label,
    });
};
