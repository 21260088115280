import React from "react";
import { TrackGoogleAnalyticsEvent } from "./GoogleAnalytics";

export default function Landing({
    projectsVisable,
    setProjectsVisable,
    appWrappedTranslator,
}) {
    // https://developer.mozilla.org/en-US/docs/Web/API/Touch/clientY
    const [touchStart, setTouchStart] = React.useState(null);
    const [touchEnd, setTouchEnd] = React.useState(null);

    const minSwipeDistance = 5;

    const onTouchStart = (e) => {
        setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientY);
    };

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientY);

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isUpSwipe = distance > minSwipeDistance;

        if (isUpSwipe) {
            setProjectsVisable(!projectsVisable);
        }
    };

    React.useEffect(() => {
        // Use setTimeout to update the message after 2000 milliseconds (2 seconds)
        const timeoutId = setTimeout(() => {
            setProjectsVisable(!projectsVisable);
        }, 2050);

        // Cleanup function to clear the timeout if the component unmounts
        return () => clearTimeout(timeoutId);
    }, []); // Empty dependency array ensures the effect runs only once

    return (
        <div
            id="appContentLanding"
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
        >
            {/* @idea move up, offset towards top, to make more visable for user? */}
            {/* <div
                className="welcome-text"
                onClick={(event) => {
                    event.preventDefault();
                    setProjectsVisable(!projectsVisable);
                }}
            >
                <span>{appWrappedTranslator("welcomeTopText")}</span>
            </div> */}
            <div
                // style={{ paddingRight: "18px", cursor: "pointer" }}
                onClick={(event) => {
                    event.preventDefault();
                    TrackGoogleAnalyticsEvent(
                        "landingPortraitSection",
                        "landingPortraitSectionClick",
                        "landingPortraitSectionClickLabel"
                    );
                    setProjectsVisable(!projectsVisable);
                }}
            >
                <img
                    src={"dot_art_profile_pic.svg"}
                    id="dotArtProfile"
                    alt="Union League Fitler Club PAFA Enterprise Business Joshua Mummert Stern Chinatown Philadelphia West Chester Pennsylvania Wrestling NCAA"
                />
            </div>
            {/* <div>
                <Button
                    className="welcome-text"
                    startIcon={<KeyboardDoubleArrowDownIcon />}
                    endIcon={<KeyboardDoubleArrowDownIcon />}
                    onClick={(event) => {
                        event.preventDefault();
                        TrackGoogleAnalyticsEvent(
                            "landingPortraitSectionButton",
                            "landingPortraitSectionButtonClick",
                            "landingPortraitSectionButtonClickLabel"
                        );
                        setProjectsVisable(!projectsVisable);
                    }}
                >
                    <span>{appWrappedTranslator("welcomeBottomText")}</span>
                </Button>
            </div> */}
        </div>
    );
}
