import React, { useState } from "react";
import Alerts from "./Alerts";
import ContactForm from "./ContactForm";
import Content from "./Content";
import { InitializeGoogleAnalytics } from "./GoogleAnalytics";
import Header from "./Header";
import { wrangledProjects } from "./projectsDataObject";
import { translatorParsed } from "./translator";

// @idea https://github.com/tsparticles/react
// https://rpj.bembi.dev/
// https://reactjsexample.com/particles-react-component-using-tsparticles/

export default function App() {
    const [activeLanguage, setActiveLanguage] = useState("English");
    const [activeLanguageKey, setActiveLanguageKey] = useState("en");
    const [projectsVisable, setProjectsVisable] = useState(false);
    const [contactFormIsOpen, setContactFormIsOpen] = useState(false);
    const [contactFormMessage, setContactFormMessage] = useState("");
    const [alertsIsOpen, setAlertsIsOpen] = useState(false);

    function appWrappedTranslator(stringKeyToTranslate) {
        return translatorParsed(activeLanguageKey, stringKeyToTranslate);
    }

    React.useEffect(() => {
        InitializeGoogleAnalytics();
    });

    return (
        <div id="app">
            <Alerts
                alertsIsOpen={alertsIsOpen}
                setAlertsIsOpen={setAlertsIsOpen}
                appWrappedTranslator={appWrappedTranslator}
            />
            <ContactForm
                contactFormIsOpen={contactFormIsOpen}
                setContactFormIsOpen={setContactFormIsOpen}
                setAlertsIsOpen={setAlertsIsOpen}
                contactFormMessage={contactFormMessage}
                setContactFormMessage={setContactFormMessage}
                appWrappedTranslator={appWrappedTranslator}
            />
            <Header
                activeLanguage={activeLanguage}
                setActiveLanguage={setActiveLanguage}
                setActiveLanguageKey={setActiveLanguageKey}
                contactFormIsOpen={contactFormIsOpen}
                setContactFormIsOpen={setContactFormIsOpen}
                projectsVisable={projectsVisable}
                setProjectsVisable={setProjectsVisable}
                appWrappedTranslator={appWrappedTranslator}
            />
            <Content
                projectsVisable={projectsVisable}
                setProjectsVisable={setProjectsVisable}
                wrangledProjects={wrangledProjects}
                setContactFormIsOpen={setContactFormIsOpen}
                setContactFormMessage={setContactFormMessage}
                appWrappedTranslator={appWrappedTranslator}
            />
        </div>
    );
}
