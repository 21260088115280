import parse from "html-react-parser";
import i18n_hub from "./i18n.json";

export function translator(langKey, stringKey) {
    return i18n_hub[langKey][stringKey]
        ? i18n_hub[langKey][stringKey]
        : i18n_hub["en"][stringKey] || stringKey;
}

export function translatorParsed(langKey, stringKey) {
    return parse(translator(langKey, stringKey));
}

export const languages_nativeSpelling_map = {
    Chinese: { nativeSpelling: "简化字" },
    English: { nativeSpelling: "English" },
    French: { nativeSpelling: "Français" },
    German: { nativeSpelling: "Deutsch" },
    // Russian: { nativeSpelling: "Русский язык" },
    Spanish: { nativeSpelling: "Español" },
};

// export const languages_nativeSpelling_map = {
//     Chinese: { nativeSpelling: "简化字" },
//     English: { nativeSpelling: "English" },
//     French: { nativeSpelling: "Français" },
//     German: { nativeSpelling: "Deutsch" },
//     // Russian: { nativeSpelling: "Русский язык" },
//     Spanish: { nativeSpelling: "Español" },
// };

export const languages = [
    "Chinese", // 简化字
    "English",
    "French", // Français
    "German", // Deutsch
    // "Russian", // Русский язык
    "Spanish", // Español
];
